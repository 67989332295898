/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    arrayOf,
    bool,
    shape,
    string,
} from 'prop-types';
import DesktopMobile from '../../../../../helpers/DesktopMobile/DesktopMobile';
import CategoryThreeRow from './ContentCategoriesLayoutVersions/CategoryThreeRow';
import CategoryTwoByTwo from './ContentCategoriesLayoutVersions/CategoryTwoByTwo';
import ShopByCategory from './ContentCategoriesLayoutVersions/ShopByCategory';
import CategoryMobile from './ContentCategoriesLayoutVersions/CategoryMobile';

const ContentCategories = ({ data, isBirthdayLPLayout }) => {
    const renderDesktopCategory = (layoutVersion) => {
        if (layoutVersion === 'single row') {
            return (
                <CategoryThreeRow data={data} />
            );
        }
        if (layoutVersion === 'two by two grid') {
            return (
                <CategoryTwoByTwo data={data} />
            );
        }
        if (layoutVersion === 'full grid') {
            return (
                <ShopByCategory data={data} isBirthdayLPLayout={isBirthdayLPLayout} />
            );
        }

        return null;
    };

    const renderMobileCategory = (layoutVersion) => {
        if (layoutVersion === 'full grid') {
            return (
                <ShopByCategory data={data} isBirthdayLPLayout={isBirthdayLPLayout} />
            );
        }

        if (layoutVersion === 'single row' || layoutVersion === 'two by two grid') {
            return (
                <CategoryMobile data={data} />
            );
        }

        return null;
    };

    return (
        <DesktopMobile
            desktop={() => (renderDesktopCategory(data?.reference?.[0]?.layout_type))}
            mobile={() => (renderMobileCategory(data?.reference?.[0]?.layout_type))}
        />
    );
};

ContentCategories.propTypes = {
    data: shape({
        reference: arrayOf(shape({
            title: string.isRequired,
            layout_type: string.isRequired,
            main_banner: {
                copy_position: string.isRequired,
                title: string.isRequired,
                subtitle: string.isRequired,
                copy_color: shape({
                    color: string.isRequired,
                }),
                background_color: shape({
                    color: string.isRequired,
                }),
                main_cta: shape({
                    link: shape({
                        title: string.isRequired,
                        href: string.isRequired,
                    }),
                    font_size: string.isRequired,
                    font_weight: string.isRequired,
                    text_decoration: string.isRequired,
                    text_transform: string.isRequired,
                    copy_color: shape({
                        color: string.isRequired,
                    }),
                    background_color: shape({
                        color: string.isRequired,
                    }),
                    is_ga_trackevent_enabled: bool.isRequired,
                }),
                image: {
                    url: string.isRequired,
                    title: string.isRequired,
                },
                mobile_image: {
                    url: string.isRequired,
                    title: string.isRequired,
                },
            },
            cta_cluster: {
                copy: string.isRequired,
                copy_color: shape({
                    color: string.isRequired,
                }),
                background_color: shape({
                    color: string.isRequired,
                }),
                cluster_position: string.isRequired,
                cta_styling: shape({
                    font_size: string.isRequired,
                    font_weight: string.isRequired,
                    text_decoration: string.isRequired,
                    text_transform: string.isRequired,
                    copy_color: shape({
                        color: string.isRequired,
                    }),
                    background_color: shape({
                        color: string.isRequired,
                    }),
                }),
                is_positioned_below_image: bool.isRequired,
                ctas: arrayOf(shape({
                    image: shape({
                        url: string.isRequired,
                        title: string.isRequired,
                    }),
                    is_ga_trackevent_enabled: bool.isRequired,
                    link: shape({
                        title: string.isRequired,
                        href: string.isRequired,
                    }),
                })),
            },
        })),
    }),
    isBirthdayLPLayout: bool.isRequired,
};

ContentCategories.defaultProps = {
    data: {},
};

export default ContentCategories;
