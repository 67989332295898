/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    string, bool, arrayOf, shape,
} from 'prop-types';
import { useUIDSeed } from 'react-uid';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import LinkOrATag from '../../../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';
import ResponsiveImage from '../../../../GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';

import { getSSRDeviceType } from '../../../../../../../state/ducks/App/App-Selectors';

const useStyles =  makeStyles((theme) => ({
    shopByCategoryRoot: {
        width: '100%',
        maxWidth: 1440,
        margin: '0 auto',
    },
    shopByCategoryMain: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            padding: 10,
        },
    },
    shopByCategory: {
        padding: 4,
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            padding: 7,
            flex: '0 0 50%',
        },
        '& img': {
            width: '100%',
            display: 'block',
        },
    },
    title: ({ styles }) =>  ({
        textAlign: 'center',
        textDecoration: styles.mainBannerStyles?.titleTextDecoration || 'underline',
        margin: '4px 0px',
        fontSize: styles.mainBannerStyles?.titleFontSize || 18,
        fontFamily: styles.mainBannerStyles?.titleFontFamily || 'LatoMedium, sans-serif',
        fontWeight: styles.mainBannerStyles?.titleFontWeight || 'bold',
        letterSpacing: 1,
    }),
    ctaBtn: ({ styles }) => ({
        left: 0,
        right: 0,
        bottom: '10%',
        position: 'absolute',
        textDecoration: 'none',
        backgroundColor: styles.ctaBlockStyles?.ctaBgColor || '#ffffff',
        width: 'fit-content',
        margin: 'auto',
        textAlign: 'center',
        padding: '8px 15px',
        color: theme.palette.cms?.black || '#000000',
        fontWeight: 'bold',
        fontSize: styles.ctaBlockStyles?.ctaLabelFontSize || 14,
        letterSpacing: 1,
        textTransform: 'uppercase',
        [theme.breakpoints.down('sm')]: {
            width: '70%',
            padding: '4px 0px',
            fontSize: 14,
        },
    }),
}));

const ShopByCategory = ({ data, isBirthdayLPLayout }) => {
    const blockData = data?.reference?.[0];
    if (!blockData) {
        return null;
    }
    const seed = useUIDSeed();
    const ssrDeviceType = useSelector(getSSRDeviceType);

    const ctaProductLength = blockData?.cta_cluster?.ctas?.length;

    const styles = {
        ctaBlockStyles: {
            ctaBgColor: blockData?.cta_cluster?.cta_styling?.background_color?.color,
            ctaLabelFontSize: blockData?.cta_cluster?.cta_styling?.font_size,
        },
        mainBannerStyles: {
            titleFontSize: blockData?.main_banner?.title_font_size,
            titleFontFamily: blockData?.main_banner?.title_font_family?.replace(/font-family: |;/g, ''),
            titleFontWeight: isBirthdayLPLayout ? 400 : '',
            titleTextDecoration: isBirthdayLPLayout ? 'none' : '',
        },
    };

    const classes = useStyles({ styles });

    // set the grid structure based on the product length
    const gridStructure = () => {
        if (isBirthdayLPLayout && ctaProductLength <= 5) {
            return ssrDeviceType === 'desktop' ? '0 0 20%' : '0 0 100%';
        }
        if (ssrDeviceType === 'desktop') {
            switch (ctaProductLength) {
                case 2:
                case 3:
                case 4:
                    return '0 0 50%';
                case 5:
                case 6:
                    return '0 0 33.333%';
                case 9:
                case 10:
                    return '0 0 20%';
                default:
                    return '0 0 25%';
            }
        }
        return '0 0 50%';
    };
    return (
        <div className={classes.shopByCategoryRoot}>
            <div className={classes.title}>{blockData.main_banner.title}</div>
            <div className={classes.shopByCategoryMain}>
                {blockData?.cta_cluster?.ctas?.map((item) => (
                    <LinkOrATag key={seed(item)} href={item?.link?.href} trackingEventLabel={item?.link?.title} isTrackEventEnabled={item?.is_ga_trackevent_enabled} className={classes.shopByCategory} style={{ flex: gridStructure() }} ariaLabel={item?.link?.title}>
                        <ResponsiveImage
                            path={item?.image?.url}
                            alt={item?.image?.title}
                            params={{}}
                        />
                        <div className={classes.ctaBtn}>
                            {item?.link?.title}
                        </div>
                    </LinkOrATag>
                ))}
            </div>
        </div>
    );
};

ShopByCategory.propTypes = {
    data: shape({
        reference: arrayOf(shape({
            main_banner: {
                copy_position: string,
                title: string,
                subtitle: string,
                copy_color: shape({
                    color: string,
                }),
                background_color: shape({
                    color: string,
                }),
                main_cta: shape({
                    link: shape({
                        title: string,
                        href: string,
                    }),
                    font_size: string,
                    font_weight: string,
                    text_decoration: string,
                    text_transform: string,
                    copy_color: shape({
                        color: string,
                    }),
                    background_color: shape({
                        color: string,
                    }),
                    is_ga_trackevent_enabled: bool,
                }),
                image: {
                    url: string,
                    title: string,
                },
                mobile_image: {
                    url: string,
                    title: string,
                },
            },
            cta_cluster: {
                copy: string,
                copy_color: shape({
                    color: string,
                }),
                background_color: shape({
                    color: string,
                }),
                cluster_position: string,
                cta_styling: shape({
                    font_size: string,
                    font_weight: string,
                    text_decoration: string,
                    text_transform: string,
                    copy_color: shape({
                        color: string,
                    }),
                    background_color: shape({
                        color: string,
                    }),
                }),
                is_positioned_below_image: bool,
                ctas: arrayOf(shape({
                    image: shape({
                        url: string,
                        title: string,
                    }),
                    is_ga_trackevent_enabled: bool,
                    link: shape({
                        title: string,
                        href: string,
                    }),
                })),
            },
        })),
    }),
    isBirthdayLPLayout: bool.isRequired,
};

ShopByCategory.defaultProps = {
    data: {},
};

export default ShopByCategory;
