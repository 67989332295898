/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    string, bool, arrayOf, shape,
} from 'prop-types';
import { useUIDSeed } from 'react-uid';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';
import LinkOrATag from '../../../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';
import ResponsiveImage from '../../../../GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';
import { parseFont } from '../../../../../../helpers/common/helper';

const useStyles = makeStyles((theme) => ({
    twoByTwoRoot: {
        width: '100%',
        maxWidth: '1440px',
        margin: '20px auto',
        justifyContent: 'center',
    },
    twoByTwoBannerContainer: {
        display: 'block',
        position: 'relative',
    },
    twoByTwoBannerImage: {
        width: '100%',
        height: '100%',
        display: 'block',
        padding: '3px',
        objectFit: 'cover',
    },
    twoByTwoBannerCopyPosition: ({ styles }) => ({
        color: styles.bannerCopyColor || '#000000',
        backgroundColor: styles.bannerCopyBgColor || '#ffffff',
        margin: '0 auto',
        position: 'absolute',
        textAlign: 'center',
        width: '30%',
        padding: '30px',
        top: '30%',
        // eslint-disable-next-line no-nested-ternary
        left: styles.bannerCopyPosition === 'left' ? '10%' : styles.bannerCopyPosition === 'center' ? '0' : 'auto',
        // eslint-disable-next-line no-nested-ternary
        right: styles.bannerCopyPosition === 'right' ? '10%' : styles.bannerCopyPosition === 'center' ? '0' : 'auto',
        [theme.breakpoints.down(1150)]: {
            top: '15%',
            width: '40%',
        },
    }),

    twoByTwoBannerCopyTitle: ({ styles }) => ({
        margin: '0 auto',
        fontSize: styles?.titleFontSize || '1.4em',
        textTransform: 'uppercase',
        fontFamily: parseFont(styles?.titleFontFamily),
        '& h1, h2, h3, h4, h5, h6, p': {
            margin: 0,
            fontWeight: 'normal',
        },
    }),
    copySubtitle: ({ styles }) => ({
        margin: '7px auto',
        fontSize: styles?.subTitleFontSize || '0.9em',
        fontWeight: '700',
    }),
    twoByTwoBannerCopyCTA: ({ styles }) => ({
        backgroundColor: styles.bannerCTABgColor || '#ffffff',
        color: styles.bannerCTACopyColor || '#000000',
        fontSize: '0.9em',
        letterSpacing: '1',
        textDecoration: styles.bannerCTATextDecoration || 'none',
        textTransform: styles.bannerCTATextTransform || 'uppercase',
    }),

    twoByTwoCTAClusterContainer: {
    },
    twoByTwoCTAClusterWrapper: {
        width: '100%',
    },
    twoByTwoCTAClusterTitle: {
    },
    twoByTwoCTAItemWrapper: {
        position: 'relative',
        '& img': {
            width: '100%',
            height: '100%',
            display: 'block',
        },
    },

    twoByTwoCTAImage: {
        height: '100%',
        padding: '3px',
    },
    bannerCTA: {
        border: 0,
        margin: 0,
        padding: 0,
        textDecoration: 'none',
    },

    twoByTwoCTACopy: ({ styles }) => ({
        margin: '0 auto',
        bottom: '10%',
        left: '0',
        right: '0',
        width: '50%',
        position: 'absolute',
        padding: '5px 0px',
        backgroundColor: styles.clusterCTACopyBgColor || '#FFFFFF',
        color: styles.clusterCTACopyColor || '#000000',
        textAlign: 'center',
        fontSize: '0.9em',
        letterSpacing: '1px',
        textDecoration: styles.clusterCTATextDecoration || 'none',
        textTransform: styles.clusterCTATextTransform || 'uppercase',
    }),
}));

const CategoryTwoByTwo = ({ data }) => {
    const blockData = data?.reference?.[0];
    if (!blockData) {
        return null;
    }
    const categoryCTACopy = blockData?.cta_cluster?.copy;
    const categoryCTAs = blockData?.cta_cluster?.ctas;
    const categoryCTAPosition = blockData?.cta_cluster?.cluster_position;
    const categoryDirection = (categoryCTAPosition === 'left') ? 'row-reverse' : 'row';

    const bannerCopy = blockData?.main_banner;
    const bannerCTA = bannerCopy?.main_cta;

    const imagePath = blockData?.main_banner?.image;

    const styles = {
        bannerCopyPosition: bannerCopy?.copy_position,
        bannerCopyColor: blockData?.main_banner?.copy_color?.color,
        bannerCopyBgColor: blockData?.main_banner?.copy_background_color?.color,
        bannerCTACopyColor: blockData?.main_banner?.main_cta?.copy_color?.color,
        bannerCTABgColor: blockData?.main_banner?.main_cta?.background_color?.color,
        bannerCTATextDecoration: blockData?.main_banner?.main_cta?.text_decoration,
        bannerCTATextTransform: blockData?.main_banner?.main_cta?.text_transform,
        clusterCTACopyBgColor: blockData?.cta_cluster?.cta_styling?.background_color?.color,
        clusterCTACopyColor: blockData?.cta_cluster?.cta_styling?.copy_color?.color,
        clusterCTATextDecoration: blockData?.cta_cluster?.cta_styling?.text_decoration,
        clusterCTATextTransform: blockData?.cta_cluster?.cta_styling?.text_transform,
        titleFontFamily: bannerCopy?.title_font_family,
        titleFontSize: bannerCopy?.title_font_size,
        subTitleFontSize: bannerCopy?.subTitleFontSize,
    };

    const classes = useStyles({ styles });

    const seed = useUIDSeed();
    const fullBannerLink = bannerCTA?.is_banner_clickable || false;
    const LoadBanner = () => (
        <>
            <ResponsiveImage
                className={classes.twoByTwoBannerImage}
                path={imagePath?.url}
                alt={bannerCopy?.subtitle || bannerCTA?.link?.title}
            />
            {bannerCopy?.title && (
                <div className={classes.twoByTwoBannerCopyPosition}>

                    <div className={classes.twoByTwoBannerCopyTitle}>
                        <ReactMarkdown
                            source={bannerCopy?.title}
                            escapeHtml={false}
                        />
                    </div>
                    {bannerCopy?.subtitle && (
                        <ReactMarkdown
                            className={classes.copySubtitle}
                            source={bannerCopy.subtitle}
                            escapeHtml={false}
                        />
                    )}
                    {bannerCTA?.link?.href && bannerCTA?.link?.title && !fullBannerLink && (
                        <LinkOrATag className={classes.twoByTwoBannerCopyCTA} href={bannerCTA.link.href} trackingEventLabel={bannerCTA.link.title} isTrackEventEnabled={bannerCTA?.is_ga_trackevent_enabled} ariaLabel={`Shop Now ${bannerCopy.title} `}>
                            {bannerCTA.link.title}
                        </LinkOrATag>
                    )}
                    {bannerCTA?.link?.href && bannerCTA?.link?.title && fullBannerLink && (
                        <span className={classes.twoByTwoBannerCopyCTA}>
                            {bannerCTA.link.title}
                        </span>
                    )}
                </div>
            )}
        </>
    );
    return (
        <>
            <Grid direction={categoryDirection} container className={classes.twoByTwoRoot}>
                <Grid item xs={8} className={classes.twoByTwoBannerContainer}>
                    {fullBannerLink ? (
                        <LinkOrATag className={classes.bannerCTA} href={bannerCTA.link.href} trackingEventLabel={bannerCTA.link.title} isTrackEventEnabled={bannerCTA?.is_ga_trackevent_enabled} ariaLabel={`Shop Now ${bannerCopy.title} `}>
                            <LoadBanner />
                        </LinkOrATag>
                    ) : <LoadBanner />}
                </Grid>
                <Grid item xs={4} className={classes.twoByTwoCTAClusterContainer}>
                    <Grid container className={classes.twoByTwoCTAClusterWrapper}>
                        {categoryCTACopy && (
                            <Grid item xs={12} className={classes.twoByTwoCTAClusterTitle}>
                                <strong>{categoryCTACopy}</strong>
                            </Grid>

                        )}
                        {categoryCTAs?.map((item) => (
                            <Grid key={seed(item)} xs={6} className={classes.twoByTwoCTAItemWrapper}>
                                <LinkOrATag href={item?.link?.href} ariaLabel={`Shop Now ${item?.aria_label || item?.image?.title}`} trackingEventLabel={item?.link?.title} isTrackEventEnabled={item?.is_ga_trackevent_enabled}>
                                    <div className={classes.twoByTwoCTAImage}>
                                        <ResponsiveImage
                                            path={item?.image?.url}
                                            alt={item?.aria_label || item?.image?.title}
                                        />
                                    </div>
                                    {item?.link?.title && (
                                        <div className={classes.twoByTwoCTACopy}>
                                            <strong>{item?.link?.title}</strong>
                                        </div>
                                    )}
                                </LinkOrATag>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

CategoryTwoByTwo.propTypes = {
    data: shape({
        reference: arrayOf(shape({
            main_banner: {
                copy_position: string,
                title: string,
                subtitle: string,
                copy_color: shape({
                    color: string,
                }),
                background_color: shape({
                    color: string,
                }),
                main_cta: shape({
                    link: shape({
                        title: string,
                        href: string,
                    }),
                    font_size: string,
                    font_weight: string,
                    text_decoration: string,
                    text_transform: string,
                    copy_color: shape({
                        color: string,
                    }),
                    background_color: shape({
                        color: string,
                    }),
                    is_ga_trackevent_enabled: bool,
                }),
                image: {
                    url: string,
                    title: string,
                },
                mobile_image: {
                    url: string,
                    title: string,
                },
            },
            cta_cluster: {
                copy: string,
                copy_color: shape({
                    color: string,
                }),
                background_color: shape({
                    color: string,
                }),
                cluster_position: string,
                cta_styling: shape({
                    font_size: string,
                    font_weight: string,
                    text_decoration: string,
                    text_transform: string,
                    copy_color: shape({
                        color: string,
                    }),
                    background_color: shape({
                        color: string,
                    }),
                }),
                is_positioned_below_image: bool,
                ctas: arrayOf(shape({
                    image: shape({
                        url: string,
                        title: string,
                    }),
                    is_ga_trackevent_enabled: bool,
                    link: shape({
                        title: string,
                        href: string,
                    }),
                })),
            },
        })),
    }),
};

CategoryTwoByTwo.defaultProps = {
    data: {},
};

export default CategoryTwoByTwo;
