/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    string, bool, arrayOf, shape,
} from 'prop-types';
import { useUIDSeed } from 'react-uid';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';
import LinkOrATag from '../../../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';
import ResponsiveImage from '../../../../GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';

const useStyles =  makeStyles(() => ({
    threeRowRoot: ({ styles }) => ({
        width: '100%',
        maxWidth: '1440px',
        margin: '20px auto',
        textAlign: 'center',
        justifyContent: 'center',
        backgroundColor: styles.bannerBgColor || '#ebebeb',
    }),

    threeRowBannerContainer: {
        position: 'relative',
    },
    threeRowBannerImage: {
        width: '100%',
        height: '100%',
        display: 'block',
        padding: '3px',
        objectFit: 'cover',
    },

    threeRowBannerCopyPosition: ({ styles }) => ({
        backgroundColor: '',
        margin: styles.bannerCopyPosition === 'center' ? '0 auto' : '0',
        maxWidth: '55%',
        position: 'absolute',
        textAlign: 'center',
        top: '25%',
        left: styles.bannerCopyPosition === 'left' ? '10%' : '0',
        right: styles.bannerCopyPosition === 'right' ? '10%' : '0',

        '& h1, h2, h3, h4, h5, h6, p': {
            margin: '0',
            fontWeight: 'normal',
        },
    }),

    threeRowBannerCopyTitle: ({ styles }) => ({
        margin: '0 auto',
        color: styles.bannerCopyColor || '#ffffff',
        fontSize: '1.4em',
        textTransform: 'uppercase',
    }),
    threeRowBannerCopyCTA: ({ styles }) => ({
        display: 'inline-block',
        backgroundColor: styles.bannerCTACopyBgColor || '#ffffff',
        color: styles.bannerCTACopyColor || '#000000',
        fontSize: '0.8em',
        letterSpacing: '1px',
        textDecoration: 'none',
        textTransform: 'uppercase',
        padding: '2% 6%',
    }),

    threeRowCTAClusterWrapper: ({ styles }) => ({
        backgroundColor: styles.clusterBgColor || '#ebebeb',
    }),

    threeRowCTAClusterContainer: {
        width: '100%',
    },

    threeRowCTAClusterTitle: {
        fontSize: '0.9em',
        fontWeight: '600',
        textAlign: 'center',
        margin: 'auto',
        padding: '15px 0px',
    },

    threeRowCTAWrapper: {
        textDecoration: 'none',
    },

    threeRowCTAImage: {
        padding: '3px',
        '& img': {
            width: '100%',
            height: '100%',
            display: 'block',
        },
    },
    threeRowCTACopy: ({ styles }) => ({
        margin: '0 auto',
        padding: '4px 0px',
        textDecoration: styles.clusterCTATextDecoration || 'none',
        fontSize: '0.8em',
        letterSpacing: '1',
        textTransform: styles.clusterCTATextTransform || 'uppercase',
        color: styles.clusterCTACopyColor || '#000000',
    }),
}));

const CategoryThreeRow = ({ data }) => {
    const blockData = data?.reference?.[0];
    if (!blockData) {
        return null;
    }
    const bannerCopy = blockData?.main_banner?.title;
    const bannerCTA = blockData?.main_banner?.main_cta;
    const bannerImage = blockData?.main_banner?.image;

    const categoryCTACopy = blockData?.cta_cluster?.copy;
    const categoryCTAs = blockData?.cta_cluster?.ctas;
    const categoryCTAPosition = blockData?.cta_cluster?.cluster_position;
    const categoryDirection = (categoryCTAPosition === 'left') ? 'row-reverse' : 'row';

    const styles = {
        bannerCopyPosition: blockData?.main_banner?.copy_position,
        bannerBgColor: blockData?.main_banner?.background_color?.color,
        bannerCopyColor: blockData?.main_banner?.copy_color?.color,
        bannerCTACopyColor: blockData?.main_banner?.main_cta?.copy_color?.color,
        bannerCTACopyBgColor: blockData?.main_banner?.main_cta?.background_color?.color,
        clusterBgColor: blockData?.cta_cluster?.background_color?.color,
        clusterCTACopyColor: blockData?.cta_cluster?.cta_styling?.copy_color?.color,
        clusterCTATextDecoration: blockData?.cta_cluster?.cta_styling?.text_decoration,
        clusterCTATextTransform: blockData?.cta_cluster?.cta_styling?.text_transform,
    };

    const classes = useStyles({ styles });

    const seed = useUIDSeed();

    return (
        <>
            <Grid direction={categoryDirection} container className={classes.threeRowRoot}>

                <Grid item xs={6} className={classes.threeRowBannerContainer}>
                    <ResponsiveImage
                        className={classes.threeRowBannerImage}
                        path={bannerImage?.url}
                        alt={bannerCTA?.link?.title || 'Three Banner Container Copy'}
                    />
                    <div className={classes.threeRowBannerCopyPosition}>
                        <div className={classes.threeRowBannerCopyTitle}>
                            <ReactMarkdown
                                source={bannerCopy}
                                escapeHtml={false}
                            />
                        </div>
                        <LinkOrATag ariaLabel={bannerCTA?.link?.title} className={classes.threeRowBannerCopyCTA} href={bannerCTA?.link?.href} trackingEventLabel={bannerCTA?.link?.title} isTrackEventEnabled={bannerCTA?.is_ga_trackevent_enabled}>
                            {bannerCTA?.link?.title}
                        </LinkOrATag>
                    </div>
                </Grid>

                <Grid item xs={6} className={classes.threeRowCTAClusterWrapper}>
                    <Grid container className={classes.threeRowCTAClusterContainer}>
                        <Grid item xs={12} className={classes.threeRowCTAClusterTitle}>
                            {categoryCTACopy}
                        </Grid>
                        {categoryCTAs?.map((item) => (
                            <Grid key={seed(item)} item xs={4}>
                                <LinkOrATag href={item?.link?.href} trackingEventLabel={item?.link?.title}isTrackEventEnabled={item?.is_ga_trackevent_enabled} className={classes.threeRowCTAWrapper}>
                                    <div className={classes.threeRowCTAImage}>
                                        <ResponsiveImage
                                            path={item?.image?.url}
                                            alt={item?.link?.title || 'Three Row Cta Copy'}
                                        />
                                    </div>
                                    <div className={classes.threeRowCTACopy}>
                                        {item?.link?.title}
                                    </div>
                                </LinkOrATag>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

            </Grid>
        </>
    );
};

CategoryThreeRow.propTypes = {
    data: shape({
        reference: arrayOf(shape({
            main_banner: {
                copy_position: string,
                title: string,
                subtitle: string,
                copy_color: shape({
                    color: string,
                }),
                background_color: shape({
                    color: string,
                }),
                main_cta: shape({
                    link: shape({
                        title: string,
                        href: string,
                    }),
                    font_size: string,
                    font_weight: string,
                    text_decoration: string,
                    text_transform: string,
                    copy_color: shape({
                        color: string,
                    }),
                    background_color: shape({
                        color: string,
                    }),
                    is_ga_trackevent_enabled: bool,
                }),
                image: {
                    url: string,
                    title: string,
                },
                mobile_image: {
                    url: string,
                    title: string,
                },
            },
            cta_cluster: {
                copy: string,
                copy_color: shape({
                    color: string,
                }),
                background_color: shape({
                    color: string,
                }),
                cluster_position: string,
                cta_styling: shape({
                    font_size: string,
                    font_weight: string,
                    text_decoration: string,
                    text_transform: string,
                    copy_color: shape({
                        color: string,
                    }),
                    background_color: shape({
                        color: string,
                    }),
                }),
                is_positioned_below_image: bool,
                ctas: arrayOf(shape({
                    image: shape({
                        url: string,
                        title: string,
                    }),
                    is_ga_trackevent_enabled: bool,
                    link: shape({
                        title: string,
                        href: string,
                    }),
                })),
            },
        })),
    }),
};

CategoryThreeRow.defaultProps = {
    data: {},
};

export default CategoryThreeRow;
