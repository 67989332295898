/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    string, bool, arrayOf, shape,
} from 'prop-types';
import { useUIDSeed } from 'react-uid';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';
import Slider from 'react-slick';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import LinkOrATag from '../../../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';
import ResponsiveImage from '../../../../GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';
import { parseFont } from '../../../../../../helpers/common/helper';

const useStyles =  makeStyles((theme) => ({
    categoriesMobileRoot: {
        width: '100%',
        margin: '20px auto',
        textAlign: 'center',
    },

    categoryTop: ({ styles }) => ({
        backgroundColor: styles.bannerBgColor || '#ebebeb',
        position: 'relative',
        height: '100%',
        '& img': {
            display: 'block',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            padding: '3px',
        },
    }),
    categoryCopySection: ({ styles }) => ({
        backgroundColor: styles.bannerCopyBgColor || '#ffffff',
        width: '90%',
        margin: '-70px auto 0',
        padding: '20px',
        display: 'inline-block',
    }),
    categoryCopyTitle: ({ styles }) => ({
        margin: '0 auto',
        fontSize: styles?.titleFontSize || '1.4em',
        textTransform: 'uppercase',
        fontFamily: parseFont(styles?.titleFontFamily),
        '& h1, h2, h3, h4, h5, h6, p': {
            margin: '0 auto',
            fontWeight: '400',
        },
    }),
    categoryCopySubtitle: ({ styles }) => ({
        margin: '7px auto',
        fontSize: styles?.subTitleFontSize || '0.8em',
        fontWeight: '600',
    }),
    // this bool from cms is for cluster cta positioning, but is being used here as a condition for main cta styling
    categoryCopyCTA: ({ styles }) => ({
        textDecoration: styles.ctaIsBelowImage ? 'none' : 'underline',
        textTransform: 'uppercase',
        fontSize: '0.8em',
        fontWeight: styles.ctaIsBelowImage ? '400' : '600',
        letterSpacing: '2px',
        color: '#000',
    }),

    categoryTwoByTwoWrapper: ({ styles }) => ({
        backgroundColor: styles.clusterBgColor || '#ebebeb',
        width: '100%',
        padding: '0px 20px',
        minHeight: 'auto',
        '& img': {
            width: '100%',
            display: 'block',
            minHeight: 150,
        },
    }),
    categorySliderWrapper: ({ styles }) => ({
        backgroundColor: styles.clusterBgColor || '#ebebeb',
        width: '100%',
        padding: '0px 30px',
        minHeight: 'auto',
        '& img': {
            width: '100%',
            display: 'block',
            minHeight: 150,
        },
    }),
    categorySlider: {
        position: 'relative',
        width: '100%',
        '& .slick-list': {
            overflow: 'hidden',
            margin: '0 auto',
            width: '95%',
        },
        '& .slick-slide': {
            display: 'inline-block',
            padding: 3,
            verticalAlign: 'top',
        },
        '& .slick-prev, & .slick-next': {
            transform: 'translate(0,-50%)',
            cursor: 'pointer',
            border: 'none',
            outline: 0,
            fontSize: 0,
            lineHeight: 0,
            top: '42%',
            width: 16,
            height: 30,
            position: 'absolute',
            background: 'transparent',
        },
        '& .slick-prev': {
            left: '-5px',
        },
        '& .slick-next': {
            right: '-5px',
        },
    },

    ctaDynamic: ({ styles }) => ({
        textAlign: 'center',
        textDecoration: 'none',
        textTransform: styles.clusterCTATextTransform || 'none',

        fontSize: '0.8em',
        letterSpacing: '1px',

        margin: '3px auto',
        padding: '4px 0px',

        left: styles.ctaIsBelowImage ? 'auto' : '0',
        right: styles.ctaIsBelowImage ? 'auto' : '0',
        bottom: styles.ctaIsBelowImage ? '0%' : '8%',
        position: styles.ctaIsBelowImage ? 'relative' : 'absolute',
        width: '75%',

        backgroundColor: styles.clusterCTACopyBgColor || '#ffffff',
        color: styles.clusterCTACopyColor || '#000000',

        [theme.breakpoints.down(600)]: {
            width: '60%',
        },
    }),
    twoByTwoCTAItemWrapper: {
        display: 'flex',
        justifyContent: 'center',
        height: 'fit-content',
        position: 'relative',
        '& img': {
            width: '100%',
            height: '100%',
            display: 'block',
        },
    },
    ctaItem: {
        position: 'relative',
        textDecoration: 'none',
    },
    ctaImage: {
        padding: '3px',
    },
    bannerCTA: {
        border: 0,
        margin: 0,
        padding: 0,
        textDecoration: 'none',
    },
}));

const CategoryMobile = ({ data }) => {
    const blockData = data?.reference?.[0];
    if (!blockData) {
        return null;
    }
    const mobileImage = blockData?.main_banner?.mobile_image;
    const mobileCopy = blockData?.main_banner;
    const mobileMainCTA = mobileCopy?.main_cta;
    // select two by two grid or single row slider in CMS
    const layout = blockData?.layout_type || 'two by two grid';
    const categoryCTAs = blockData?.cta_cluster;
    const styles = {
        ctaIsBelowImage: blockData?.cta_cluster?.is_positioned_below_image,
        clusterCTATextTransform: blockData?.cta_cluster?.cta_styling?.text_transform,
        clusterCTACopyColor: blockData?.cta_cluster?.cta_styling?.copy_color?.color,
        clusterCTACopyBgColor: blockData?.cta_cluster?.cta_styling?.background_color?.color,
        clusterBgColor: blockData?.cta_cluster?.background_color?.color,
        bannerCopyBgColor: blockData?.main_banner?.copy_background_color?.color,
        bannerBgColor: blockData?.main_banner?.background_color?.color,
        titleFontFamily: mobileCopy?.title_font_family,
        titleFontSize: mobileCopy?.title_font_size,
        subTitleFontSize: mobileCopy?.subTitleFontSize,
    };

    const classes = useStyles({ styles });
    const seed = useUIDSeed();
    const fullBannerLink = mobileCopy?.main_cta?.is_banner_clickable || false;

    const LoadBanner = () => (
        <>
            <ResponsiveImage
                path={mobileImage?.url}
                alt={mobileImage?.description || 'Background Image'}
            />
            <div className={classes.categoryCopySection}>
                <div className={classes.categoryCopyTitle}>
                    <ReactMarkdown source={mobileCopy?.title} escapeHtml={false} />
                </div>
                {mobileCopy?.subtitle && (
                    <ReactMarkdown
                        className={classes.categoryCopySubtitle}
                        source={mobileCopy?.subtitle}
                        escapeHtml={false}
                    />
                )}
                {!fullBannerLink && (
                    <LinkOrATag ariaLabel={mobileCopy?.title} className={classes.categoryCopyCTA} href={mobileMainCTA?.link?.href} trackingEventLabel={mobileMainCTA?.link?.title} isTrackEventEnabled={mobileMainCTA?.is_ga_trackevent_enabled}>
                        {mobileCopy?.main_cta?.link?.title}
                    </LinkOrATag>
                )}
                {fullBannerLink && (
                    <span className={classes.bannerCTA}>
                        {mobileCopy?.main_cta?.link?.title}
                    </span>
                )}
            </div>
        </>
    );
    return (
        <Grid container className={classes.categoriesMobileRoot}>
            <Grid item xs={12} className={classes.categoryTop}>
                {fullBannerLink ? (
                    <LinkOrATag ariaLabel={mobileCopy?.title} className={classes.categoryCopyCTA} href={mobileMainCTA?.link?.href} trackingEventLabel={mobileMainCTA?.link?.title} isTrackEventEnabled={mobileMainCTA?.is_ga_trackevent_enabled}>
                        <LoadBanner />
                    </LinkOrATag>
                ) : <LoadBanner />}
            </Grid>
            <Grid item xs={12}>
                {layout === 'two by two grid' ? (
                    <Grid container className={classes.categoryTwoByTwoWrapper}>
                        {categoryCTAs?.ctas?.map((item) => (
                            <Grid key={seed(item)} xs={6} className={classes.twoByTwoCTAItemWrapper}>
                                <LinkOrATag
                                    className={classes.ctaItem}
                                    href={item.link?.href}
                                    ariaLabel={item.aria_label || item?.image?.title}
                                    trackingEventLabel={item.link?.href}
                                    isTrackEventEnabled={item.is_ga_trackevent_enabled}
                                >
                                    <div className={classes.ctaImage}>
                                        <ResponsiveImage
                                            path={item.image?.url}
                                            alt={item.link?.title}
                                        />
                                    </div>
                                    {item.link?.title && (
                                        <div className={classes.ctaDynamic}>
                                            {item.link.title}
                                        </div>
                                    )}
                                </LinkOrATag>
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <div className={classes.categorySliderWrapper}>
                        <Slider
                            dots={false}
                            speed={800}
                            infinite
                            slidesToShow={2}
                            slidesToScroll={1}
                            nextArrow={<ChevronRightIcon />}
                            prevArrow={<ChevronLeftIcon />}
                            className={`carousel-container ${classes.categorySlider}`}
                        >
                            {categoryCTAs?.ctas?.map((item) => (
                                <LinkOrATag
                                    key={seed(item)}
                                    className={classes.ctaItem}
                                    href={item.link?.href}
                                    ariaLabel={item.aria_label || item?.image?.title}
                                    trackingEventLabel={item.link?.href}
                                    isTrackEventEnabled={item.is_ga_trackevent_enabled}
                                >
                                    <div className={classes.ctaImage}>
                                        <ResponsiveImage
                                            path={item.image?.url}
                                            alt={item.link?.title}
                                        />
                                    </div>
                                    {item.link?.title && (
                                        <div className={classes.ctaDynamic}>
                                            {item.link.title}
                                        </div>
                                    )}
                                </LinkOrATag>
                            ))}
                        </Slider>
                    </div>
                )}
            </Grid>
        </Grid>
    );
};

CategoryMobile.propTypes = {
    data: shape({
        reference: arrayOf(shape({
            main_banner: {
                copy_position: string,
                title: string,
                subtitle: string,
                copy_color: shape({
                    color: string,
                }),
                background_color: shape({
                    color: string,
                }),
                main_cta: shape({
                    link: shape({
                        title: string,
                        href: string,
                    }),
                    font_size: string,
                    font_weight: string,
                    text_decoration: string,
                    text_transform: string,
                    copy_color: shape({
                        color: string,
                    }),
                    background_color: shape({
                        color: string,
                    }),
                    is_ga_trackevent_enabled: bool,
                }),
                image: {
                    url: string,
                    title: string,
                },
                mobile_image: {
                    url: string,
                    title: string,
                },
            },
            cta_cluster: {
                copy: string,
                copy_color: shape({
                    color: string,
                }),
                background_color: shape({
                    color: string,
                }),
                cluster_position: string,
                cta_styling: shape({
                    font_size: string,
                    font_weight: string,
                    text_decoration: string,
                    text_transform: string,
                    copy_color: shape({
                        color: string,
                    }),
                    background_color: shape({
                        color: string,
                    }),
                }),
                is_positioned_below_image: bool,
                ctas: arrayOf(shape({
                    image: shape({
                        url: string,
                        title: string,
                    }),
                    is_ga_trackevent_enabled: bool,
                    link: shape({
                        title: string,
                        href: string,
                    }),
                })),
            },
        })),
    }),
};

CategoryMobile.defaultProps = {
    data: {},
};

export default CategoryMobile;
